<template>
  <div class="ratio">
    <div class="container">
      <figure>
        <slot />
      </figure>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@mixin proportional-box($a: 1, $b: $a) {
  position: absolute;
  left: calc(50cqw - #{$a/$b/2} * 100cqh);
  top: var(--margin);
  width: calc(#{$a/$b} * 100cqh);
  height: 100cqh;
  @container (max-aspect-ratio: #{$a}/#{$b}) {
    top: calc(50cqh - #{$b/$a/2} * 100cqw);
    left: 0;
    width: 100cqw;
    height: calc(#{$b/$a} * 100cqw);
  }
}

.ratio {
  --margin: 32px;
  height: calc(100% - var(--margin) * 2);
  position: relative;

  @media (orientation: portrait) {
    --margin: 0px;
  }

  .container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    container: card / size;
    container-name: card;
  }

  figure {
    @include proportional-box(595, 842);
    display: block;
  }
}
</style>
