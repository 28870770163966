<template>
  <div class="poster bg" :class="{ inverse: inverse }">
    <div class="toggle" @click="inverse = !inverse" />
    <div class="container" ref="container">
      <World />
    </div>
  </div>
</template>

<script>
import World from "./World";

import { mapMutations } from "vuex";

export default {
  components: {
    World,
  },
  data() {
    return {
      startOffset: 0,
      fontSize: 100,
      inverse: false,
      loaded: false,
    };
  },
  methods: {
    ...mapMutations(["setGlobalBackgroundColor"]),
  },
  watch: {
    inverse() {
      const color = this.inverse ? "black" : "#f4f1ec";
      this.setGlobalBackgroundColor({ color });
    },
  },
  mounted() {
    this.setGlobalBackgroundColor({ color: "#f4f1ec" });
  },
  beforeUnmount() {},
};
</script>

<style lang="scss" scoped>
.inverse {
  .bg,
  &.bg {
    color: black;
    background: black;
  }

  &::after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: red;
    top: 0px;
    left: 0px;
    opacity: 0.4;
    mix-blend-mode: color-burn;
    pointer-events: none;
  }
}
.bg {
  color: black;
  background-color: #f4f1ec;
}
.poster {
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  position: relative;

  @supports (height: 100svh) {
    height: 100svh;
  }
}
.container {
  --padding: 0;
  width: 100vw;
  height: 100vh;
  position: relative;
}
.border {
  position: absolute;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  //border-radius: 18vw;
  background: currentcolor;
  clip-path: url(#squircleClip);
}
svg {
  overflow: visible;
  pointer-events: none;
}
text {
  text-transform: uppercase;
  stroke: none;
  fill: currentcolor;

  .tint {
    //fill: black;
  }
}
#path {
  fill: transparent;
  stroke: transparent;
  stroke-width: 2;
}
.toggle {
  position: absolute;
  width: 88px;
  height: 88px;
  top: 0px;
  left: 0px;
  z-index: 10;
}
</style>
