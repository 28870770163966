<template>
  <div class="poster bg" :class="{ inverse: inverse }">
    <div class="toggle" @click="inverse = !inverse" />
    <div class="container" ref="container">
      <!-- Border -->
      <div class="border" />

      <!-- World -->

      <!-- Mask -->
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        style="position: absolute; opacity: 0"
      >
        <clipPath id="squircleClip" clipPathUnits="objectBoundingBox">
          <path
            fill="red"
            stroke="none"
            d="M 0,0.5 C 0,0 0,0 0.5,0 S 1,0 1,0.5 1,1 0.5,1 0,1 0,0.5"
          />
        </clipPath>
      </svg>

      <!-- Marquee -->
      <svg ref="ellipse" id="ellipse" version="1.1" viewBox="0 0 1000 1000">
        <path
          ref="path"
          id="path"
          d="M100,500A400,400 0 0 0 900 500 A400,400 0 0 0 100 500"
        />

        <text>
          <textPath
            ref="textPath"
            xlink:href="#path"
            dy="5"
            id="tp"
            lengthAdjust="spacingAndGlyphs"
          >
            {{ text }}
          </textPath>
        </text>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default:
        "Hola Tola • Hola Tola • Hola Tola • Hola Tola • Hola Tola • Hola Tola •",
    },
  },
  data() {
    return {
      startOffset: 0,
      fontSize: 100,
      inverse: false,
      ready: false,
    };
  },
  methods: {
    Init() {
      const bounds = this.$refs.container.getBoundingClientRect();
      let w = bounds.width;
      let h = bounds.height;

      this.$refs.ellipse.setAttributeNS(null, "viewBox", `0 0 ${w}  ${h}`);

      let vw = w / 10;
      let vh = h;
      let vh2 = h / 2;
      let vw2 = w / 2;
      let vw3 = w - vw;

      let d = `M0 ${vh2}
      C0 0 0 0 ${vw2} 0C${w} 0 ${w} 0 ${w} ${vh2}C${w} ${h} ${w} ${h} ${vw2} ${h}C0 ${h} 0 ${h} 0 ${vh2}Z
      C0 0 0 0 ${vw2} 0C${w} 0 ${w} 0 ${w} ${vh2}C${w} ${h} ${w} ${h} ${vw2} ${h}C0 ${h} 0 ${h} 0 ${vh2}Z`;

      this.$refs.path.setAttributeNS(null, "d", d);
      let path_length = this.$refs.path.getTotalLength();

      //begin at a bigger size than needed
      this.fontSize = 100;
      this.$refs.ellipse.style.fontSize = this.fontSize + "px";

      // while the text length is bigger than half path length
      while (this.$refs.textPath.getComputedTextLength() > path_length / 2) {
        //reduce the font size
        this.fontSize -= 0.25;
        //reset the font size
        this.$refs.ellipse.style.fontSize = this.fontSize + "px";
      }
      setTimeout(() => {
        this.ready = true;
      }, 1000);
    },
    Marquee() {
      requestAnimationFrame(this.Marquee);
      if (!this.$refs.textPath) return;
      this.$refs.textPath.setAttributeNS(
        null,
        "startOffset",
        this.startOffset + "%"
      );
      if (this.startOffset >= 50) {
        this.startOffset = 0;
      }
      this.startOffset += 0.04;
    },
  },
  mounted() {
    document.fonts.ready.then(() => {
      setTimeout(() => {
        this.Init();
        window.addEventListener("resize", this.Init, false);
      }, 200);
    });

    this.Marquee();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.Init, false);
  },
};
</script>

<style lang="scss" scoped>
.poster {
  font-family: ABC;
  width: 100%;
  height: 100%;
  position: absolute;
  display: grid;
  place-items: center;
  position: relative;
  /* transform-origin: 95% 95%;
  transform-origin: 5% 5%;
  transform: scale(2); */
}
.container {
  --padding: 17%;
  width: calc(100% - (var(--padding)));
  height: calc(100% - (var(--padding)));
  // outline: 1px solid blue;
  position: relative;
}

svg {
  overflow: visible;
  pointer-events: none;
}
text {
  text-transform: uppercase;
  stroke: none;
  fill: currentcolor;
}
#path {
  fill: transparent;
  stroke: transparent;
  stroke-width: 2;
}
.toggle {
  position: absolute;
  width: 88px;
  height: 88px;
  top: 0px;
  left: 0px;
  z-index: 10;
}
</style>
