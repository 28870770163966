import { createRouter, createWebHashHistory } from "vue-router";

import Gallery from "@/views/Gallery/Gallery";
import Palooza4 from "@/views/Palooza4/Palooza4";
import HolidaySpecial1 from "@/views/HolidaySpecial1/Holiday1";
import Palooza5 from "@/views/Palooza5/Home";

import Playground from "@/views/Playground/Playground";

import Timeline from "@/views/Gif/Timeline";

const routes = [
  {
    path: "/",
    name: "Gallery",
    component: Gallery,
  },
  {
    path: "/playground",
    name: "Playground",
    component: Playground,
  },
  {
    path: "/4",
    name: "Palooza4",
    component: Palooza4,
  },
  {
    path: "/holiday-special",
    name: "HolidaySpecial1",
    component: HolidaySpecial1,
  },
  {
    path: "/5",
    name: "Palooza5",
    component: Palooza5,
  },
  {
    path: "/gif",
    name: "Timeline",
    component: Timeline,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
