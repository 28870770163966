import Matter from "matter-js";

export default function ({
  element,
  width = window.innerWidth,
  height = window.innerHeight,
  path,
}) {
  let Engine = Matter.Engine,
    Render = Matter.Render,
    Runner = Matter.Runner,
    Composites = Matter.Composites,
    Common = Matter.Common,
    MouseConstraint = Matter.MouseConstraint,
    Mouse = Matter.Mouse,
    Composite = Matter.Composite,
    Query = Matter.Query,
    Svg = Matter.Svg,
    Bodies = Matter.Bodies,
    canMove = false;

  // // provide concave decomposition support library
  // Common.setDecomp(require("poly-decomp"));

  // create engine
  var engine = Engine.create(),
    world = engine.world;

  // create renderer
  var render = Render.create({
    element,
    engine: engine,
    options: {
      width,
      height,
      wireframes: false,
      background: "transparent",
    },
  });

  engine.world.gravity.y = 0;

  Render.setPixelRatio(render, "auto");

  Render.run(render);

  // create runner
  var runner = Runner.create();
  Runner.run(runner, engine);

  // add bodies
  var offset = -10,
    options = {
      isStatic: true,
      render: {
        visible: false,
      },
    };

  world.bodies = [];

  // these static walls will not be rendered in this sprites example, see options

  Composite.add(world, [
    Bodies.rectangle(width / 2, -offset, width + 2 * offset, 50.5, options),
    Bodies.rectangle(
      width / 2,
      height + offset,
      width + 2 * offset,
      50.5,
      options
    ),
    Bodies.rectangle(
      width + offset,
      height / 2,
      50.5,
      height + 2 * offset,
      options
    ),
    Bodies.rectangle(-offset, height / 2, 50.5, height + 2 * offset, options),
  ]);

  // var stack = Composites.stack(20, 20, 11, 11, 10, 10, function (x, y) {
  //   // if (Query.point([terrain], { x: x, y: y }).length === 0) {
  //   return Bodies.rectangle(x, y, 128, 128, {
  //     density: 0.0005,
  //     frictionAir: 0.06,
  //     restitution: 0.3,
  //     friction: 0.01,
  //     render: {
  //       sprite: {
  //         texture: require("@/assets/img/guillaume.png"),
  //         xScale: 0.35,
  //         yScale: 0.35,
  //       },
  //     },
  //   });
  //   // }
  // });

  const mobileScale = window.innerWidth >= 500 ? 1 : 0.5;

  const emojis = [
    {
      delay: 1000,
      src: "evan.png",
      scale: 0.45,
      x: 0.81,
      y: 0.9,
      width: 72,
      height: 72,
    },
    {
      delay: 8000,
      src: "sammy.png",
      scale: 0.5,
      x: 0.1,
      y: 0.1,
      width: 72,
      height: 72,
    },
    {
      delay: 1000,
      src: "marco.png",
      scale: 0.5,
      x: 0.8,
      y: 0.84,
      width: 120,
      height: 300,
    },
    {
      delay: 0,
      src: "bianca.png",
      scale: 0.5,
      x: 0.5,
      y: 0.5,
      width: 112,
      height: 180,
    },
    {
      delay: 7000,
      src: "guillaume.png",
      scale: 0.75,
      x: 0.5,
      y: 0.1,
      width: 96,
      height: 96,
    },
    {
      delay: 5000,
      src: "alex-2.png",
      scale: 0.5,
      x: 0.5,
      y: 0.9,
      width: 125,
      height: 125,
    },
    {
      delay: 3000,
      src: "party-alen-2.png",
      scale: 0.5,
      x: 0.85,
      y: 0.15,
      width: 102,
      height: 140,
    },
    {
      delay: 2000,
      src: "sam.png",
      scale: 1,
      x: 0.15,
      y: 0.75,
      width: 81,
      height: 109,
    },
    {
      delay: 2000,
      src: "evan-2.png",
      scale: 1,
      x: 0.22,
      y: 0.7,
      width: 128,
      height: 128,
    },
    {
      delay: 2000,
      src: "trevor.png",
      scale: 1,
      x: 0.22,
      y: 0.8,
      width: 92,
      height: 114,
    },
    {
      delay: 6000,
      src: "redbull.png",
      scale: 1,
      x: 0.65,
      y: 0.62,
      width: 56,
      height: 128,
    },
    {
      delay: 4000,
      src: "jakob.png",
      scale: 0.5,

      x: 0.18,
      y: 0.4,
      width: 125,
      height: 135,
    },
    {
      delay: 6000,
      src: "tom.png",
      scale: 0.5,
      x: 0.68,
      y: 0.4,
      width: 150,
      height: 250,
    },
    {
      delay: 9000,
      src: "nick.png",
      scale: 0.5,
      x: 0.35,
      y: 0.15,
      width: 128,
      height: 128,
    },
    {
      delay: 10000,
      src: "paul.png",
      scale: 0.75,
      x: 0.5,
      y: 0.5,
      width: 289,
      height: 289,
    },
  ];

  // var stack2 = Composites.stack(2, 2, 24, 10, 30, 30, function (x, y) {
  //   // if (Query.point([terrain], { x: x, y: y }).length === 0) {
  //   var e = emojis[Math.floor(Math.random() * emojis.length)];
  //   return Bodies.rectangle(x, y, e.width / 4, e.height / 4, {
  //     density: 0.0005,
  //     frictionAir: 0.06,
  //     restitution: 0.3,
  //     friction: 0.01,
  //     render: {
  //       sprite: {
  //         texture: require(`@/assets/img/${e.src}`),
  //         xScale: e.scale / 4,
  //         yScale: e.scale / 4,
  //       },
  //     },
  //   });
  //   // }
  // });

  emojis.forEach((emoji) => {
    setTimeout(() => {
      Composite.add(
        world,
        Bodies.rectangle(
          width * emoji.x,
          height * emoji.y,
          emoji.width * mobileScale,
          emoji.height * mobileScale,
          {
            density: 0.0005,
            frictionAir: 0.06,
            restitution: 0.3,
            friction: 0.01,
            render: {
              sprite: {
                texture: require(`@/assets/img/${emoji.src}`),
                xScale: emoji.scale * mobileScale,
                yScale: emoji.scale * mobileScale,
              },
            },
          }
        )
      );
    }, emoji.delay + 2000);
  });

  setTimeout(() => {
    // if (window.innerWidth >= 500) {
    //   Composite.add(world, stack2);
    // }
    engine.world.gravity.y = 1;
    canMove = true;
  }, 11500);

  //Composite.add(world, stack);

  // add mouse control
  var mouse = Mouse.create(render.canvas),
    mouseConstraint = MouseConstraint.create(engine, {
      mouse: mouse,
      constraint: {
        stiffness: 0.2,
        render: {
          visible: false,
        },
      },
    });

  Composite.add(world, mouseConstraint);

  // keep the mouse in sync with rendering
  render.mouse = mouse;

  // fit the render viewport to the scene
  Render.lookAt(render, {
    min: { x: 0, y: 0 },
    max: { x: width, y: height },
  });

  // context for MatterTools.Demo
  return {
    engine: engine,
    runner: runner,
    render: render,
    canvas: render.canvas,
    stop: function () {
      Matter.Render.stop(render);
      Matter.Runner.stop(runner);
      render.canvas.remove();
      render.canvas = null;
      render.context = null;
      render.textures = {};
    },
    move: function (x, y) {
      if (!canMove) return;
      engine.world.gravity.x = x;
      engine.world.gravity.y = y;
    },
    addButt: function (x, y) {
      const body = Bodies.rectangle(x, y, 100, 100, {
        density: 0.0005,
        frictionAir: 0.06,
        restitution: 0.3,
        friction: 0.01,
        render: {
          sprite: {
            texture: require(`@/assets/img/paul.png`),
            xScale: 0.3460207612,
            yScale: 0.3460207612,
          },
        },
      });
      Matter.Body.rotate(body, Common.random(0, 360));

      Composite.add(world, body);
    },
    add: function (x, y) {
      const index = Common.random(0, emojis.length - 1);

      const es = [...emojis];

      //es.pop();

      var e = es[Math.floor(Common.random(0, es.length - 1))];

      const body = Bodies.rectangle(x, y, e.width / 2, e.height / 2, {
        density: 0.0005,
        frictionAir: 0.06,
        restitution: 0.3,
        friction: 0.01,
        render: {
          sprite: {
            texture: require(`@/assets/img/${e.src}`),
            xScale: e.scale / 2,
            yScale: e.scale / 2,
          },
        },
      });

      Matter.Body.rotate(body, Common.random(0, 360));

      Composite.add(world, body);
    },
  };
}
