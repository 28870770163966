<template>
  <div ref="container" class="phone" :class="{ controls: controls, show: show }" />
</template>

<script>
import {
  Scene,
  WebGLRenderer,
  PerspectiveCamera,
  Clock,
  TextureLoader,
  VideoTexture,
  MeshPhysicalMaterial,
  Color,
  MathUtils,
  Vector2,
  Vector3,
  Vector4,
  Box3,
  ReinhardToneMapping,
  ACESFilmicToneMapping,
  EquirectangularRefractionMapping,
  RepeatWrapping,
  Quaternion,
  Matrix4,
  Spherical,
  Sphere,
  Raycaster,
} from "three";

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import postprocessing from "./postprocessing";

import { debounce } from "lodash";

import CameraControls from "camera-controls";

const subsetOfTHREE = {
  Vector2,
  Vector3,
  Vector4,
  Quaternion,
  Matrix4,
  Spherical,
  Box3,
  Sphere,
  Raycaster,
};

CameraControls.install({ THREE: subsetOfTHREE });

export default {
  props: {
    videoTextures: {
      type: Array,
      default: () => [],
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
    autoplay: Boolean,
    controls: Boolean,
    show: Boolean,
  },

  data() {
    return {
      transitionDuration: 250,
      width: 0, // container width
      height: 0, // container height
      disableAutoRotate: false,
      userDragging: false,
      initialCamera: {
        y: 0,
        x: 0,
        rx: MathUtils.degToRad(0),
        ry: MathUtils.degToRad(80),
        zoom: 0.45,
      },
    };
  },

  mounted() {
    this.initThree();
    this.createCameraControls();
    this.initPostProcessing();
    this.loadGLB();
    this.loadHDR();
    this.addResizeObserver();
  },

  beforeUnmount() {
    // Stop the animation
    this.stop();
    // Remove the canvas from the DOM
    this.renderer.domElement.remove();
    // Dispose the renderer
    this.renderer.dispose();

    this.cameraControls.dispose();

    this.resizeObserver.disconnect();
  },

  methods: {
    initThree() {
      this.container = null;
      this.scene = new Scene();
      this.renderer = new WebGLRenderer({
        //powerPreference: "high-performance",
        // antialias: false,
        // stencil: false,
        // depth: false,
        alpha: true,
      });
      this.loader = new GLTFLoader();
      this.camera = null; // we'll initialize this late;
      this.resizeObserver = null;
      this.frameId = null; // this will hold the animation frame i;
      this.clock = new Clock();

      this.container = this.$refs.container;
      this.width = this.container.clientWidth;
      this.height = this.container.clientHeight;
      this.camera = new PerspectiveCamera(35, this.width / this.height, 0.1, 100);

      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(this.width, this.height);
      //this.renderer.toneMapping = ReinhardToneMapping;
      this.renderer.toneMapping = ACESFilmicToneMapping;
      this.renderer.toneMappingExposure = 1;

      this.container.appendChild(this.renderer.domElement);
    },

    createCameraControls() {
      this.cameraControls = new CameraControls(this.camera, document.querySelector("#app"));
      // disable wheel and touch events

      const onRest = () => {
        this.cameraControls.removeEventListener("rest", onRest);
        this.userDragging = false;
        this.disableAutoRotate = false;
      };

      this.cameraControls.addEventListener("controlstart", () => {
        this.cameraControls.removeEventListener("rest", onRest);
        this.userDragging = true;
        this.disableAutoRotate = true;
      });
      this.cameraControls.addEventListener("controlend", () => {
        if (this.cameraControls.active) {
          this.cameraControls.addEventListener("rest", onRest);
        } else {
          onRest();
        }
      });

      //
      this.cameraControls.addEventListener("transitionstart", () => {
        if (this.userDragging) return;

        this.disableAutoRotate = true;
        this.cameraControls.addEventListener("rest", onRest);
      });

      this.cameraControls.mouseButtons.wheel = CameraControls.ACTION.NONE;

      return;
      if (this.controls) {
        this.cameraControls.mouseButtons.left = CameraControls.ACTION.TRUCK;
        //this.cameraControls.mouseButtons.wheel = CameraControls.ACTION.ROTATE;
        return;
      }

      this.cameraControls.mouseButtons.left = CameraControls.ACTION.NONE;
      this.cameraControls.mouseButtons.right = CameraControls.ACTION.NONE;
      this.cameraControls.mouseButtons.middle = CameraControls.ACTION.NONE;

      this.cameraControls.touches.one = CameraControls.ACTION.NONE;
      this.cameraControls.touches.two = CameraControls.ACTION.NONE;
      this.cameraControls.touches.three = CameraControls.ACTION.NONE;
    },

    initPostProcessing() {
      this.effects = postprocessing({
        renderer: this.renderer,
        camera: this.camera,
        scene: this.scene,
      });
    },

    loadGLB() {
      // You should replace this with your own GLB file path
      this.loader.load(require("./the_little_mermaid.glb"), (gltf) => {
        this.scene.add(gltf.scene);
        this.mesh = gltf.scene;

        // traverse scene
        gltf.scene.traverse((object) => {
          // display
          if (object.name === "LLCOsMNMwTSiaFM_0") this.setVideoTexture(object);
        });

        // center and make it fit
        this.normalizeObject(this.mesh);

        // reset
        this.resetCamera();

        this.$emit("ready");
      });
    },

    setImageTexture(object) {
      return;
      //       const loader = new TextureLoader();
      //
      //       loader.load(
      //         // resource URL
      //         require("@/assets/img/mockup_6.jpg"),
      //
      //         // onLoad callback
      //         function (texture) {
      //           texture.wrapS = RepeatWrapping;
      //           texture.wrapT = RepeatWrapping;
      //
      //           texture.center = new Vector2(0.5, 0.5);
      //           texture.rotation = Math.PI;
      //           texture.flipY = false;
      //
      //           texture.repeat.set(2.15, 1); // You may need to adjust these values
      //
      //           // in this function we create the material and add it to the object
      //           object.material = new MeshPhysicalMaterial({
      //             map: texture,
      //             clearcoat: 1,
      //             metalness: 1,
      //             reflectivity: 1,
      //             roughness: 0.2,
      //             color: new Color("#fff"),
      //           });
      //         },
      //
      //         // onProgress callback currently not supported
      //         undefined,
      //
      //         // onError callback
      //         function (error) {
      //           console.error("An error happened during loading of texture");
      //         }
      //       );
    },

    setVideoTexture(object) {
      return;
      if (this.video) return;

      // video material
      this.video = document.createElement("video");

      //this.video.src = this.params.video;

      //this.video.src = require("@/assets/video/app-test.mp4");
      //this.video.src = require("@/assets/video/sun-test.mp4");
      this.video.autoplay = false;
      this.video.controls = false;
      this.video.muted = true;
      this.video.loop = true;
      this.video.playsInline = true;
      this.video.preload = "metadata";

      this.video.oncanplay = () => {
        const texture = new VideoTexture(this.video);

        texture.wrapS = RepeatWrapping;
        texture.wrapT = RepeatWrapping;

        texture.center = new Vector2(0.5, 0.5);
        texture.rotation = Math.PI;
        texture.flipY = false;
        texture.repeat.set(2.15, 1); // You may need to adjust these values

        texture.needsUpdate = true;

        object.material = new MeshPhysicalMaterial({
          clearcoat: 0.1,
          metalness: 0.85,
          reflectivity: 0,
          roughness: 0.35,
          color: new Color("#fff"),
          map: texture,
        });
      };
    },

    loadHDR(hdr = 8) {
      const hdrs = [
        "sunflowers_puresky_1k.hdr",
        "blue_photo_studio_1k.hdr",
        "industrial_sunset_puresky_1k.hdr",
        "mud_road_puresky_1k.hdr", // 3 nice muted colors
        "sunflowers_puresky_1k.hdr", // 4 also nice ^^
        "table_mountain_2_puresky_1k.hdr",
        "industrial_sunset_02_puresky_1k.hdr",
        "aristea_wreck_puresky_1k.hdr",
        "wasteland_clouds_puresky_1k.hdr", // 8 nice ---> bright
        "rocky_ridge_puresky_1k.hdr",
        "marry_hall_1k.hdr", // 10 - nice
        "studio_small_05_1k.hdr",
        "blocky_photo_studio_1k.hdr",
        "neon_photostudio_1k.hdr",
      ];

      new RGBELoader().load(require(`./hdr/${hdrs[hdr]}`), (texture) => {
        texture.mapping = EquirectangularRefractionMapping;
        this.scene.environment = texture;
        //this.scene.background = new Color("#ddd");
      });
    },

    normalizeObject(object) {
      const box = new Box3().setFromObject(object);
      const size = box.getSize(new Vector3()).length();
      const center = box.getCenter(new Vector3());
      this.camera.near = size / 10;
      this.camera.far = size * 100;
      this.camera.updateProjectionMatrix();

      const w = box.getSize(new Vector3()).x;

      object.position.x += object.position.x - center.x;
      object.position.y += object.position.y - center.y;
      object.position.z += object.position.z - center.z;
    },

    updateVideoTexture() {
      // Implementation of video texture update logic
      // You should replace this with your own logic
    },

    fitToBounds({ top = 0, bottom = 0, left = 0, right = 0 } = {}) {
      if (!this.mesh) return;
      function clamp(val, min, max) {
        return Math.min(Math.max(val, min), max);
      }

      //const padding = clamp((400 / 1728) * this.width, 16, 400);

      const fov = this.camera.fov * MathUtils.DEG2RAD;
      const rendererHeight = this.renderer.getSize(new Vector2()).height;
      const boundingBox = new Box3().setFromObject(this.mesh);
      const size = boundingBox.getSize(new Vector3());
      const boundingWidth = size.x;
      const boundingHeight = size.y;
      const boundingDepth = size.z;

      var distanceToFit = this.cameraControls.getDistanceToFitBox(
        boundingWidth,
        boundingHeight,
        boundingDepth
      );
      var paddingTop = 0;
      var paddingBottom = 0;
      var paddingLeft = 0;
      var paddingRight = 0;

      // loop to find almost convergence points
      for (var i = 0; i < 10; i++) {
        const depthAt = distanceToFit - boundingDepth * 0.5;
        const cssPixelToUnit = (2 * Math.tan(fov * 0.5) * Math.abs(depthAt)) / rendererHeight;
        paddingTop = top * cssPixelToUnit;
        paddingBottom = bottom * cssPixelToUnit;
        paddingLeft = left * cssPixelToUnit;
        paddingRight = right * cssPixelToUnit;

        distanceToFit = this.cameraControls.getDistanceToFitBox(
          boundingWidth + paddingLeft + paddingRight,
          boundingHeight + paddingTop + paddingBottom,
          boundingDepth
        );
      }

      this.cameraControls.fitToBox(this.mesh, false, {
        paddingLeft,
        paddingRight,
        paddingBottom,
        paddingTop,
      });
    },

    rotateTo({ x = Math.PI, y = Math.PI * 0.5 } = {}) {
      this.cameraControls.rotateTo(x, y, false);
    },

    zoomTo(zoom = 1) {
      this.cameraControls.zoomTo(zoom, false);
    },

    moveTo({ x = 0, y = 0 } = {}) {
      this.savedX = x;
      this.savedY = y;
      this.cameraControls.moveTo(x, y, false);
    },

    addResizeObserver() {
      // Implementation of resize observer logic
      // You should replace this with your own logic
      this.resizeObserver = new ResizeObserver(this.onResize);
      this.resizeObserver.observe(this.container);
    },

    resetCamera() {
      this.cameraControls.reset();
      const { rx, ry, zoom } = this.initialCamera;
      this.rotateTo({ x: rx, y: ry });
      this.zoomTo(zoom);

      this.cameraControls.update();
      this.fitToBounds();
    },

    onResize: debounce(function onDebounceRerender(e) {
      if (!this.mesh) return;

      // enable transition
      this.transitionDuration = 250;

      const prevValues = {
        zoom: this.camera.zoom,
        rx: this.cameraControls.azimuthAngle,
        ry: this.cameraControls.polarAngle,
        x: this.savedX,
        y: this.savedY,
      };

      this.width = this.container.clientWidth;
      this.height = this.container.clientHeight;

      this.camera.aspect = this.width / this.height;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(this.width, this.height);

      // reset
      this.resetCamera();

      // revert back
      const { x, y, zoom, rx, ry } = prevValues;

      this.moveTo({ x, y });
      this.zoomTo(zoom);
      this.rotateTo({
        x: rx,
        y: ry,
      });
    }, 0),

    start() {
      if (this.frameId) return;
      this.animate();
    },

    stop() {
      if (!this.frameId) return;
      cancelAnimationFrame(this.frameId);
      this.frameId = null;
    },

    render() {
      const delta = this.clock.getDelta();
      const elapsed = this.clock.getElapsedTime();
      const updated = this.cameraControls.update(delta);

      if (!this.disableAutoRotate) {
        this.cameraControls.azimuthAngle += 20 * delta * MathUtils.DEG2RAD;
      }

      this.effects.composer.render();
    },

    animate() {
      this.render();
      this.frameId = requestAnimationFrame(this.animate);
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.start();
      } else {
        this.stop();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.phone {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  pointer-events: none;
  opacity: 0;

  &.show {
    opacity: 1;
  }
}
</style>
