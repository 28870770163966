import Matter from "matter-js";
import gsap from "gsap";
import { scaleLinear } from "d3-scale";

/** large number to avoid faces falling through the walls on resize */
const thickness = 6000;
/** bounding box on svg, used to correctly scale sprites */
const svgBoundingBox = 144;

// play around with these parameters to change size and layout
/** scale factor of faces */
let scaleFactor = 0.75;
let scaleFunc = scaleLinear().domain([390, 1728]).range([0.75, 4]);
/** connected to scale factor */
const widthBase = 1728;
const xx = 0;
const yy = 0;
const columns = 8;
const rows = 8;
const columnGap = 0;
const rowGap = 0;

const renderProps = {
  isStatic: true,
  render: {
    visible: false,
  },
};

function calculateScale(width) {
  scaleFactor = scaleFunc(width);
  return scaleFactor;
  return Math.max((scaleFactor / widthBase) * width, scaleFactor);
}

function map(value, low1, high1, low2, high2) {
  return Math.min(
    Math.max(low2 + ((high2 - low2) * (value - low1)) / (high1 - low1), low2),
    high2
  );
}

export default function ({ element, width = 800, height = 600 }) {
  // height = height - 50;
  var Engine = Matter.Engine,
    Render = Matter.Render,
    Runner = Matter.Runner,
    Composites = Matter.Composites,
    Common = Matter.Common,
    MouseConstraint = Matter.MouseConstraint,
    Mouse = Matter.Mouse,
    Composite = Matter.Composite,
    World = Matter.World,
    Bodies = Matter.Bodies;

  // create engine
  var engine = Engine.create({
    positionIterations: 10,
    velocityIterations: 10,
  });

  var world = engine.world;

  world.gravity.x = -0.15;
  world.gravity.y = -0;
  /** will be calculated dynamically depending on element width */
  let scale = calculateScale(width);

  // create renderer
  var render = Render.create({
    element,
    engine: engine,
    options: {
      width,
      height,
      wireframes: false,
      background: "transparent",
      // showStats: true,
      // showPerformance: true,
    },
  });

  Render.setPixelRatio(render, "auto");
  Render.run(render);

  // create runner
  var runner = Runner.create({
    isFixed: true,
  });
  Runner.run(runner, engine);

  let walls = {
    top: Matter.Body,
    bottom: Matter.Body,
    left: Matter.Body,
    right: Matter.Body,
  };

  let faces;
  let timeline;

  const removeFaces = () => {
    if (!faces) return;
    if (timeline) timeline.clear();
    Composite.allBodies(faces).forEach((body) => {
      Composite.remove(engine.world, body);
    });
    Composite.clear(faces, false);
  };
  const addFaces = (src = "party-alen.png") => {
    removeFaces();

    timeline = gsap.timeline({});

    const count = Common.random(50, 100);
    const radius = 24 * scale;
    const size = radius * 2;

    faces = Composite.create();
    for (let n = 0; n < count; n++) {
      const emoji = Array.isArray(src) ? src[Math.floor(Math.random() * src.length)] : src;

      const x = Common.random(size, width - size);
      const y = Common.random(size, height - size);

      const face = Bodies.polygon(x, y, 1, radius, {
        density: 0.0009,
        frictionAir: 0.01,
        restitution: 0.3,
        friction: 0.09,
        render: {
          sprite: {
            texture: require(`./img/${emoji}`),
            xScale: (radius / 128) * 2,
            yScale: (radius / 128) * 2,
          },
        },
      });

      timeline.to(
        {},
        {
          duration: 0.2,
          onStart: () => {
            Composite.add(faces, face);
          },
        }
      );
    }

    Composite.add(world, faces);

    return;

    faces = Composites.stack(xx, yy, columns, rows, columnGap, rowGap, function (x, y) {
      const sides = 1; //Math.round(Common.random(1, 8));
      const radius = Common.random(24, 24) * scale;

      const sprites = ["t", "o", "l", "a"];

      return Bodies.polygon(x, y, sides, radius, {
        density: 0.0009,
        frictionAir: 0.01,
        restitution: 0.3,
        friction: 0.09,
        render: {
          sprite: {
            texture: require(`./img/${src}`),
            // texture: require(`./svg/${
            //   sprites[Math.floor(Math.random() * sprites.length)]
            // }.svg`),
            xScale: (radius / 128) * 2,
            yScale: (radius / 128) * 2,
          },
        },
      });
    });

    Composite.add(world, faces);
  };

  const start = () => {
    addFaces();
    walls = {
      bottom: Bodies.rectangle(width / 2, height + thickness / 2, thickness, thickness, {
        ...renderProps,
      }),

      top: Bodies.rectangle(width / 2, -thickness / 2, thickness, thickness, {
        ...renderProps,
      }),

      left: Bodies.rectangle(0 - thickness / 2, height / 2, thickness, height * 5, {
        ...renderProps,
      }),

      right: Bodies.rectangle(width + thickness / 2, height / 2, thickness, height * 5, {
        ...renderProps,
      }),
    };

    //Composite.add(world, faces);
    Composite.add(world, Object.values(walls));

    //  add mouse control
    var mouse = Mouse.create(render.canvas),
      mouseConstraint = MouseConstraint.create(engine, {
        mouse: mouse,
        constraint: {
          stiffness: 0.02,
          render: {
            visible: false,
          },
        },
      });

    Composite.add(world, mouseConstraint);

    // keep the mouse in sync with rendering
    render.mouse = mouse;

    // fit the render viewport to the scene
    Render.lookAt(render, {
      min: { x: 0, y: 0 },
      max: { x: width, y: height },
    });
  };

  start();

  function resize(width, height) {
    if (!walls) {
      return;
    }
    render.options.width = width;
    render.options.height = height;
    render.bounds.max.x = render.bounds.min.x + width;
    render.bounds.max.y = render.bounds.min.y + height;

    // update scale of faces
    const oldScale = scale;
    scale = calculateScale(width);

    if (scale !== oldScale) {
      const newScale = scale / oldScale;

      faces.bodies.forEach((ball) => {
        if (ball.render.sprite) {
          ball.render.sprite.xScale = ball.render.sprite.xScale * newScale;
          ball.render.sprite.yScale = ball.render.sprite.yScale * newScale;
          Matter.Body.scale(ball, newScale, newScale);
        }
      });
    }

    if (render.options.pixelRatio !== 1) {
      Render.setPixelRatio(render, render.options.pixelRatio || 2);
    } else {
      render.canvas.width = width;
      render.canvas.height = height;
    }

    // fit the render viewport to the scene
    Render.lookAt(render, {
      min: { x: 0, y: 0 },
      max: { x: width, y: height },
    });

    // reposition ground
    Matter.Body.setPosition(
      walls.bottom,
      Matter.Vector.create(width / 2, height + thickness / 2)
    );

    // reposition right wall
    Matter.Body.setPosition(
      walls.right,
      Matter.Vector.create(width + thickness / 2, height / 2)
    );
  }

  //   function resize(width, height) {
  //     render.options.width = width;
  //     render.options.height = height;
  //     render.bounds.max.x = render.bounds.min.x + width;
  //     render.bounds.max.y = render.bounds.min.y + height;
  //
  //     if (render.options.pixelRatio !== 1) {
  //       Render.setPixelRatio(render, render.options.pixelRatio);
  //     } else {
  //       render.canvas.width = width;
  //       render.canvas.height = height;
  //     }
  //
  //     // fit the render viewport to the scene
  //     Render.lookAt(render, {
  //       min: { x: 0, y: 0 },
  //       max: { x: width, y: height },
  //     });
  //     //     render.bounds.max.x = width;
  //     //     render.bounds.max.y = height;
  //     //     render.options.width = width;
  //     //     render.options.height = height;
  //     //     render.canvas.width = width * window.devicePixelRatio;
  //     //     render.canvas.height = height * window.devicePixelRatio;
  //     //
  //     //     render.canvas.style.width = `${width}px`;
  //     //     render.canvas.style.height = `${height}px`;
  //   }

  // context for MatterTools.Demo
  return {
    engine: engine,
    runner: runner,
    render: render,
    canvas: render.canvas,
    resize,
    addFaces,
    removeFaces,
    start,
    stop: function () {
      Matter.Render.stop(render);
      Matter.Runner.stop(runner);

      Matter.World.clear(world);
      Matter.Engine.clear(engine);
      render.canvas.remove();
      render.canvas = null;
      render.context = null;
      render.textures = {};
    },
  };
}
