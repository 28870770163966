<template>
  <div class="poster bg" :class="{ inverse: inverse }">
    <div class="toggle" @click="inverse = !inverse" />
    <div class="container" ref="container" :style="{ opacity: ready ? 1 : 0 }">
      <!-- World -->
      <World v-if="ready" @slowmo="onSlowMoChange" @highfi="onHighFiChange" />
      <!-- Mask -->
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        style="position: absolute; opacity: 0"
      >
        <clipPath id="squircleClip" clipPathUnits="objectBoundingBox">
          <path
            fill="red"
            stroke="none"
            d="M 0,0.5 C 0,0 0,0 0.5,0 S 1,0 1,0.5 1,1 0.5,1 0,1 0,0.5"
          />
        </clipPath>
      </svg>
      <!-- Marquee -->
      <svg ref="ellipse" id="ellipse" version="1.1" viewBox="0 0 1000 1000">
        <path
          ref="path"
          id="path"
          d="M100,500A400,400 0 0 0 900 500 A400,400 0 0 0 100 500"
        />

        <text>
          <textPath
            ref="textPath"
            xlink:href="#path"
            dy="5"
            id="tp"
            lengthAdjust="spacingAndGlyphs"
          >
            Tola presents • A Holiday Special • December 5th, 2022 •
            <tspan class="tint">
              "Fantastic Visions and Where to Find Them" by Alen
            </tspan>
            • Cocktails by Trevor • Food by Marco • Anatomy Lessons by Alex •
          </textPath>
        </text>
      </svg>
    </div>
  </div>
</template>

<script>
import World from "./World";

import { mapMutations } from "vuex";

import preloader from "z-image-preloader";

export default {
  components: {
    World,
  },
  data() {
    return {
      startOffset: 0,
      fontSize: 100,
      inverse: false,
      ready: false,
      loaded: false,
      audioSrc: require("@/assets/sound/mistetoe.m4a"),
    };
  },
  methods: {
    ...mapMutations(["setGlobalBackgroundColor"]),
    Init() {
      const bounds = this.$refs.container.getBoundingClientRect();
      let w = bounds.width;
      let h = bounds.height;

      this.$refs.ellipse.setAttributeNS(null, "viewBox", `0 0 ${w}  ${h}`);

      let vw = w / 10;
      let vh = h;
      let vh2 = h / 2;
      let vw2 = w / 2;
      let vw3 = w - vw;

      let d = `M0 ${vh2}
      C0 0 0 0 ${vw2} 0C${w} 0 ${w} 0 ${w} ${vh2}C${w} ${h} ${w} ${h} ${vw2} ${h}C0 ${h} 0 ${h} 0 ${vh2}Z
      C0 0 0 0 ${vw2} 0C${w} 0 ${w} 0 ${w} ${vh2}C${w} ${h} ${w} ${h} ${vw2} ${h}C0 ${h} 0 ${h} 0 ${vh2}Z`;
      this.$refs.path.setAttributeNS(null, "d", d);
      let path_length = this.$refs.path.getTotalLength();

      //begin at a bigger size than needed
      this.fontSize = 200;
      this.$refs.ellipse.style.fontSize = this.fontSize + "px";

      // while the text length is bigger than half path length
      while (this.$refs.textPath.getComputedTextLength() > path_length / 2) {
        //reduce the font size
        this.fontSize -= 0.25;
        //reset the font size
        this.$refs.ellipse.style.fontSize = this.fontSize + "px";
      }
      setTimeout(() => {
        this.ready = true;
      }, 1000);
    },
    Marquee() {
      requestAnimationFrame(this.Marquee);
      if (!this.$refs.textPath) return;
      this.$refs.textPath.setAttributeNS(
        null,
        "startOffset",
        this.startOffset + "%"
      );
      if (this.startOffset >= 50) {
        this.startOffset = 0;
      }
      this.startOffset += 0.04;
    },
    onKeydown(e) {
      if (e.key === "d") {
        this.inverse = !this.inverse;
      }
    },
    createAudio() {
      this.audio = new Audio(this.audioSrc);
      this.audio.loop = true;
      this.audio.play();
    },
    onSlowMoChange(isSlowMo) {
      if (!this.audio) return false;
      this.audio.playbackRate = isSlowMo ? 0.5 : 1;
    },
    onHighFiChange(isHighFi) {
      this.audio.playbackRate = isHighFi ? 2 : 1;
    },
  },
  watch: {
    inverse() {
      const color = this.inverse ? "black" : "#f4f1ec";
      this.setGlobalBackgroundColor({ color });
    },
  },
  mounted() {
    this.setGlobalBackgroundColor({ color: "#f4f1ec" });

    document.fonts.ready.then(() => {
      setTimeout(() => {
        this.Init();
        window.addEventListener("resize", this.Init, false);
      }, 200);
    });

    this.Marquee();

    document.addEventListener("keydown", this.onKeydown);
    this.createAudio();

    // preload
    this.$nextTick(() => {
      const images = [];
      for (var i = 0; i < 11; i++) {
        images.push(require(`./assets/img/${i}.png`));
      }
      preloader.batchLoad(this.images).then(() => {
        this.loaded = true;
      });
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.Init, false);
    document.removeEventListener("keydown", this.onKeydown);

    this.audio.pause();
    this.audio = null;
  },
};
</script>

<style lang="scss" scoped>
.inverse {
  .bg,
  &.bg {
    color: red;
    background: black;
  }

  &::after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: red;
    top: 0px;
    left: 0px;
    opacity: 0.4;
    mix-blend-mode: color-burn;
    pointer-events: none;
  }
}
.bg {
  color: red;
  background-color: #f4f1ec;
}
.poster {
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  position: relative;

  @supports (height: 100svh) {
    height: 100svh;
  }
}
.container {
  --padding: clamp(50px, 10vw, 110px);
  width: calc(100vw - (var(--padding)));
  height: calc(100vh - (var(--padding)));
  // outline: 1px solid blue;
  position: relative;
  transition: opacity var(--transition-duration-default)
    var(--transition-ease-drawer);

  @supports (height: 100svh) {
    height: calc(100svh - (var(--padding)));
  }
}
.border {
  position: absolute;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  //border-radius: 18vw;
  background: currentcolor;
  clip-path: url(#squircleClip);
}
svg {
  overflow: visible;
  pointer-events: none;
}
text {
  text-transform: uppercase;
  stroke: none;
  fill: currentcolor;

  .tint {
    //fill: black;
  }
}
#path {
  fill: transparent;
  stroke: transparent;
  stroke-width: 2;
}
.toggle {
  position: absolute;
  width: 88px;
  height: 88px;
  top: 0px;
  left: 0px;
  z-index: 10;
}
</style>
