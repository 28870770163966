import Matter from "matter-js";

export default function ({
  element,
  width = window.innerWidth,
  height = window.innerHeight,
  path,
}) {
  var Engine = Matter.Engine,
    Events = Matter.Events,
    Render = Matter.Render,
    Runner = Matter.Runner,
    Body = Matter.Body,
    Common = Matter.Common,
    Composite = Matter.Composite,
    Composites = Matter.Composites,
    Constraint = Matter.Constraint,
    MouseConstraint = Matter.MouseConstraint,
    Mouse = Matter.Mouse,
    Bodies = Matter.Bodies,
    Vector = Matter.Vector;

  const heads = 11;
  let headIndex = -1;
  const gravityX = 0;
  const gravityY = width > height ? 2 : 0.5;

  const columns = width > height ? 10 : 5;
  const rows = width > height ? 7 : 11;
  const globalScale = Common.clamp((width / 1728) * 0.55, 0.3);
  let paused = false;
  let isSlowMo = false;
  let isHighFi = false;

  const Ragdoll = function (x, y, scale, options, randomHead) {
    scale = typeof scale === "undefined" ? 1 : scale;

    let texture;
    if (!randomHead) {
      headIndex++;
      if (headIndex >= heads) headIndex = 0;
      texture = require(`./assets/img/${headIndex}.png`);
    } else {
      const randomHeadIndex = Math.floor(Math.random() * (10 - 0 + 1) + 0);
      texture = require(`./assets/img/${randomHeadIndex}.png`);
    }

    var Body = Matter.Body,
      Bodies = Matter.Bodies,
      Constraint = Matter.Constraint,
      Composite = Matter.Composite,
      Common = Matter.Common;

    var headOptions = Common.extend(
      {
        label: "head",
        collisionFilter: {
          group: Body.nextGroup(true),
        },
        chamfer: {
          radius: [15 * scale, 15 * scale, 15 * scale, 15 * scale],
        },
        render: {
          fillStyle: "red",
          sprite: {
            texture,
            xScale: 0.6 * scale,
            yScale: 0.6 * scale,
          },
        },
      },
      options
    );

    var chestOptions = Common.extend(
      {
        label: "chest",
        collisionFilter: {
          group: Body.nextGroup(true),
        },
        chamfer: {
          radius: [20 * scale, 20 * scale, 26 * scale, 26 * scale],
        },
        render: {
          fillStyle: "red",
        },
      },
      options
    );

    var leftArmOptions = Common.extend(
      {
        label: "left-arm",
        collisionFilter: {
          group: Body.nextGroup(true),
        },
        chamfer: {
          radius: 10 * scale,
        },
        render: {
          fillStyle: "red",
        },
      },
      options
    );

    var leftLowerArmOptions = Common.extend({}, leftArmOptions, {
      render: {
        fillStyle: "red",
      },
    });

    var rightArmOptions = Common.extend(
      {
        label: "right-arm",
        collisionFilter: {
          group: Body.nextGroup(true),
        },
        chamfer: {
          radius: 10 * scale,
        },
        render: {
          fillStyle: "red",
        },
      },
      options
    );

    var rightLowerArmOptions = Common.extend({}, rightArmOptions, {
      render: {
        fillStyle: "red",
      },
    });

    var leftLegOptions = Common.extend(
      {
        label: "left-leg",
        collisionFilter: {
          group: Body.nextGroup(true),
        },
        chamfer: {
          radius: 10 * scale,
        },
        render: {
          fillStyle: "red",
        },
      },
      options
    );

    var leftLowerLegOptions = Common.extend({}, leftLegOptions, {
      render: {
        fillStyle: "red",
      },
    });

    var rightLegOptions = Common.extend(
      {
        label: "right-leg",
        collisionFilter: {
          group: Body.nextGroup(true),
        },
        chamfer: {
          radius: 10 * scale,
        },
        render: {
          fillStyle: "red",
        },
      },
      options
    );

    var rightLowerLegOptions = Common.extend({}, rightLegOptions, {
      render: {
        fillStyle: "red",
      },
    });

    var head = Bodies.rectangle(
      x,
      y - 60 * scale,
      34 * scale,
      40 * scale,
      headOptions
    );
    var chest = Bodies.rectangle(x, y, 55 * scale, 80 * scale, chestOptions);

    var duckDick = Bodies.rectangle(x, y, 60 * scale, 60 * scale, {
      label: "duck-dick",
      collisionFilter: {
        group: Body.nextGroup(true),
      },
      render: {
        visible: false,
      },
    });

    var duckDickConstraint = Constraint.create({
      bodyA: chest,
      bodyB: duckDick,
      pointA: {
        x: 0,
        y: 40 * scale,
      },
      pointB: {
        x: 0,
        y: -25 * scale,
      },
      stiffness: 0.001,
      render: {
        strokeStyle: "red",
        lineWidth: 5,
      },
    });

    var rightUpperArm = Bodies.rectangle(
      x + 39 * scale,
      y - 15 * scale,
      20 * scale,
      40 * scale,
      rightArmOptions
    );
    var rightLowerArm = Bodies.rectangle(
      x + 39 * scale,
      y + 25 * scale,
      20 * scale,
      60 * scale,
      rightLowerArmOptions
    );
    var leftUpperArm = Bodies.rectangle(
      x - 39 * scale,
      y - 15 * scale,
      20 * scale,
      40 * scale,
      leftArmOptions
    );
    var leftLowerArm = Bodies.rectangle(
      x - 39 * scale,
      y + 25 * scale,
      20 * scale,
      60 * scale,
      leftLowerArmOptions
    );
    var leftUpperLeg = Bodies.rectangle(
      x - 20 * scale,
      y + 57 * scale,
      20 * scale,
      40 * scale,
      leftLegOptions
    );
    var leftLowerLeg = Bodies.rectangle(
      x - 20 * scale,
      y + 97 * scale,
      20 * scale,
      60 * scale,
      leftLowerLegOptions
    );
    var rightUpperLeg = Bodies.rectangle(
      x + 20 * scale,
      y + 57 * scale,
      20 * scale,
      40 * scale,
      rightLegOptions
    );
    var rightLowerLeg = Bodies.rectangle(
      x + 20 * scale,
      y + 97 * scale,
      20 * scale,
      60 * scale,
      rightLowerLegOptions
    );

    var chestToRightUpperArm = Constraint.create({
      bodyA: chest,
      pointA: {
        x: 24 * scale,
        y: -23 * scale,
      },
      pointB: {
        x: 0,
        y: -8 * scale,
      },
      bodyB: rightUpperArm,
      stiffness: 0.6,
      render: {
        visible: false,
      },
    });

    var chestToLeftUpperArm = Constraint.create({
      bodyA: chest,
      pointA: {
        x: -24 * scale,
        y: -23 * scale,
      },
      pointB: {
        x: 0,
        y: -8 * scale,
      },
      bodyB: leftUpperArm,
      stiffness: 0.6,
      render: {
        visible: false,
      },
    });

    var chestToLeftUpperLeg = Constraint.create({
      bodyA: chest,
      pointA: {
        x: -10 * scale,
        y: 30 * scale,
      },
      pointB: {
        x: 0,
        y: -10 * scale,
      },
      bodyB: leftUpperLeg,
      stiffness: 0.6,
      render: {
        visible: false,
      },
    });

    var chestToRightUpperLeg = Constraint.create({
      bodyA: chest,
      pointA: {
        x: 10 * scale,
        y: 30 * scale,
      },
      pointB: {
        x: 0,
        y: -10 * scale,
      },
      bodyB: rightUpperLeg,
      stiffness: 0.6,
      render: {
        visible: false,
      },
    });

    var upperToLowerRightArm = Constraint.create({
      bodyA: rightUpperArm,
      bodyB: rightLowerArm,
      pointA: {
        x: 0,
        y: 15 * scale,
      },
      pointB: {
        x: 0,
        y: -25 * scale,
      },
      stiffness: 0.6,
      render: {
        visible: false,
      },
    });

    var upperToLowerLeftArm = Constraint.create({
      bodyA: leftUpperArm,
      bodyB: leftLowerArm,
      pointA: {
        x: 0,
        y: 15 * scale,
      },
      pointB: {
        x: 0,
        y: -25 * scale,
      },
      stiffness: 0.6,
      render: {
        visible: false,
      },
    });

    var upperToLowerLeftLeg = Constraint.create({
      bodyA: leftUpperLeg,
      bodyB: leftLowerLeg,
      pointA: {
        x: 0,
        y: 20 * scale,
      },
      pointB: {
        x: 0,
        y: -20 * scale,
      },
      stiffness: 0.6,
      render: {
        visible: false,
      },
    });

    var upperToLowerRightLeg = Constraint.create({
      bodyA: rightUpperLeg,
      bodyB: rightLowerLeg,
      pointA: {
        x: 0,
        y: 20 * scale,
      },
      pointB: {
        x: 0,
        y: -20 * scale,
      },
      stiffness: 0.6,
      render: {
        visible: false,
      },
    });

    var headContraint = Constraint.create({
      bodyA: head,
      pointA: {
        x: 0,
        y: 25 * scale,
      },
      pointB: {
        x: 0,
        y: -35 * scale,
      },
      bodyB: chest,
      stiffness: 0.6,
      render: {
        visible: false,
      },
    });

    var legToLeg = Constraint.create({
      bodyA: leftLowerLeg,
      bodyB: rightLowerLeg,
      stiffness: 0.01,
      render: {
        visible: false,
      },
    });

    const bodies = [
      chest,
      head,
      leftLowerArm,
      leftUpperArm,
      rightLowerArm,
      rightUpperArm,
      leftLowerLeg,
      rightLowerLeg,
      leftUpperLeg,
      rightUpperLeg,
    ];
    const constraints = [
      upperToLowerLeftArm,
      upperToLowerRightArm,
      chestToLeftUpperArm,
      chestToRightUpperArm,
      headContraint,
      upperToLowerLeftLeg,
      upperToLowerRightLeg,
      chestToLeftUpperLeg,
      chestToRightUpperLeg,
      legToLeg,
    ];

    if (headIndex === 10 && !randomHead) {
      bodies.push(duckDick);
      constraints.push(duckDickConstraint);
    }

    var person = Composite.create({
      bodies,
      constraints,
    });

    return person;
  };

  //const canMove = true;

  // create engine
  var engine = Engine.create(),
    world = engine.world;

  // create renderer
  var render = Render.create({
    element,
    engine: engine,
    options: {
      width,
      height,
      showAngleIndicator: false,
      wireframes: false,
      background: "transparent",
    },
  });

  const createScaledRagDoll = function (_s = 1, randomHead = false) {
    const ragDollX = width > height ? width * 0.15 : width * 0.15;
    const ragDollY = width > height ? -1000 : height * Common.random(0.2, 0.8);
    const ragDollScale = Common.random(4, 5) * globalScale * _s;
    return Ragdoll(ragDollX, ragDollY, ragDollScale, {}, randomHead);
  };

  engine.world.gravity.x = gravityX;
  engine.world.gravity.y = gravityY;

  Render.run(render);

  // create runner
  var runner = Runner.create();
  Runner.run(runner, engine);

  // create stairs
  var stairCount = (render.bounds.max.y - render.bounds.min.y) / 50;

  var stack = Composites.stack(
    0,
    0,
    stairCount + 2,
    1,
    0,
    0,
    function (x, y, column) {
      return Bodies.rectangle(x - 50, y + column * 50, 100, 5, {
        isStatic: true,
        render: {
          fillStyle: "red",
          strokeStyle: "red",
          lineWidth: 0,
          visible: false,
        },
      });
    }
  );

  // create obstacles

  //xx, yy, columns, rows, columnGap, rowGap;

  var obstacles = Composites.stack(
    40,
    0,
    columns,
    rows,
    20,
    20,
    function (x, y, column) {
      const texture = Common.choose([
        require(`./assets/t.svg`),
        require(`./assets/o-01.svg`),
        require(`./assets/o-02.svg`),
        require(`./assets/o-03.svg`),
        require(`./assets/o-04.svg`),
        require(`./assets/o-05.svg`),
        require(`./assets/o-01.svg`),
        require(`./assets/pay.svg`),
        require(`./assets/get-paid.svg`),
        require(`./assets/l.svg`),
        require(`./assets/a.svg`),
      ]);

      //const scale = 0.6;
      return Bodies.circle(x, y, 125 * globalScale, {
        render: {
          fillStyle: "blue",
          sprite: {
            texture,
            xScale: globalScale,
            yScale: globalScale,
          },
        },
      });

      var sides = Math.round(Common.random(1, 8)),
        options = {
          render: {
            strokeStyle: Common.choose(["red", "red", "red", "red", "red"]),
            lineWidth: 5,
            fillStyle: "transparent",
          },
        };

      switch (Math.round(Common.random(0, 1))) {
        case 0:
          if (Common.random() < 0.8) {
            return Bodies.rectangle(
              x,
              y,
              Common.random(25, 250),
              Common.random(25, 250),
              options
            );
          } else {
            return Bodies.rectangle(
              x,
              y,
              Common.random(80, 120),
              Common.random(25, 30),
              options
            );
          }
        case 1:
          return Bodies.polygon(x, y, sides, Common.random(25, 50), options);
      }
    }
  );

  var ragdolls = Composite.create();

  var smallRagdolls = Composite.create();

  for (var i = 0; i < 1; i += 1) {
    var ragdoll = createScaledRagDoll();

    Composite.add(ragdolls, ragdoll);
  }

  Composite.add(world, [obstacles, ragdolls, smallRagdolls]);

  var offset = -10,
    options = {
      isStatic: true,
      render: {
        visible: false,
      },
    };

  world.bodies = [];

  // these static walls will not be rendered in this sprites example, see options

  const wall = Bodies.rectangle(
    (width * 1) / 2 - 10,
    height * 1 + 40,
    width * 1 + 2 * offset,
    100,
    options
  );
  Matter.Body.rotate(wall, 0);

  Composite.add(world, [
    //Bodies.rectangle(width / 2, -offset, width + 2 * offset, 5.5, options),
    wall,
    Bodies.rectangle(-4, height / 2, 20, height + 2 * offset, options),
  ]);

  var timeScaleTarget = 1,
    counter = 0;

  Events.on(engine, "afterUpdate", function (event) {
    if (paused || isSlowMo || isHighFi) return;
    // tween the timescale for slow-mo
    if (mouse.button === -1) {
      engine.timing.timeScale +=
        (timeScaleTarget - engine.timing.timeScale) * 0.05;
    } else {
      engine.timing.timeScale = 1;
    }

    counter += 1;

    // every 1.5 sec
    if (counter >= 60 * 1.5) {
      // flip the timescale
      if (timeScaleTarget < 1) {
        timeScaleTarget = 1;
      } else {
        timeScaleTarget = 0.05;
      }

      // reset counter
      counter = 0;
    }

    for (var i = 0; i < stack.bodies.length; i += 1) {
      var body = stack.bodies[i];

      // animate stairs
      Body.translate(body, {
        x: -0.5 * engine.timing.timeScale,
        y: -0.5 * engine.timing.timeScale,
      });

      // loop stairs when they go off screen
      if (body.position.x < -50) {
        Body.setPosition(body, {
          x: 50 * (stack.bodies.length - 1),
          y:
            25 +
            render.bounds.max.y +
            (body.bounds.max.y - body.bounds.min.y) * 0.5,
        });

        Body.setVelocity(body, {
          x: 0,
          y: 0,
        });
      }
    }

    for (i = 0; i < ragdolls.composites.length; i += 1) {
      var ragdoll = ragdolls.composites[i],
        bounds = Composite.bounds(ragdoll);

      // move ragdolls back to the top of the screen
      if (bounds.min.y > render.bounds.max.y + 100) {
        // Composite.translate(ragdoll, {
        //   x: -bounds.min.x * 0.9,
        //   y: -render.bounds.max.y - 400,
        // });
        Composite.remove(ragdolls, ragdoll);
        var newRagdoll = createScaledRagDoll();
        Composite.add(ragdolls, newRagdoll);
      }
    }

    for (i = 0; i < obstacles.bodies.length; i += 1) {
      var body = obstacles.bodies[i],
        bounds = body.bounds;

      // move obstacles back to the top of the screen
      if (bounds.min.y > render.bounds.max.y + 100) {
        Body.translate(body, {
          x: -bounds.min.x,
          y: -render.bounds.max.y - 300,
        });
      }
    }
  });

  // add mouse control and make the mouse revolute
  var mouse = Mouse.create(render.canvas),
    mouseConstraint = MouseConstraint.create(engine, {
      mouse: mouse,
      constraint: {
        stiffness: 0.6,
        length: 0,
        angularStiffness: 0,
        render: {
          visible: false,
        },
      },
    });

  Composite.add(world, mouseConstraint);

  // keep the mouse in sync with rendering
  render.mouse = mouse;

  // fit the render viewport to the scene
  Render.lookAt(render, {
    min: { x: 0, y: 0 },
    max: { x: width, y: height },
  });

  return {
    engine: engine,
    runner: runner,
    render: render,
    canvas: render.canvas,
    pause: function () {
      paused = !paused;
      Matter.Runner.enabled = !paused;
    },
    newDoll: function () {
      var ragdoll = ragdolls.composites[0];
      Composite.remove(ragdolls, ragdoll);

      var newRagdoll = createScaledRagDoll();
      Composite.add(ragdolls, newRagdoll);
    },
    newSmallDoll: function () {
      var smallRagdoll = createScaledRagDoll(0.35, true);
      Composite.add(smallRagdolls, smallRagdoll);
    },
    slowMo: function () {
      isHighFi = false;
      isSlowMo = !isSlowMo;
      engine.timing.timeScale = isSlowMo ? 0.05 : 1;
      return isSlowMo;
    },
    highFi: function () {
      isSlowMo = false;
      isHighFi = !isHighFi;
      engine.timing.timeScale = isHighFi ? 1 : 1;
      engine.world.gravity.x = isHighFi ? -0.5 : gravityX;
      engine.world.gravity.y = isHighFi ? 1.5 : gravityY;
      return isHighFi;
    },
    stop: function () {
      Matter.Render.stop(render);
      Matter.Runner.stop(runner);
      render.canvas.remove();
      render.canvas = null;
      render.context = null;
      render.textures = {};
    },
    move: function (x, y) {
      if (!canMove) return;
      engine.world.gravity.x = x;
      engine.world.gravity.y = y;
    },
    add: function (x, y) {
      const index = Common.random(0, emojis.length - 1);

      const es = [...emojis];

      es.pop();

      var e = es[Math.floor(Common.random(0, es.length - 1))];

      const body = Bodies.rectangle(x, y, e.width / 2, e.height / 2, {
        density: 0.0005,
        frictionAir: 0.06,
        restitution: 0.3,
        friction: 0.01,
        render: {
          sprite: {
            texture: require(`@/assets/img/${e.src}`),
            xScale: e.scale / 2,
            yScale: e.scale / 2,
          },
        },
      });

      Matter.Body.rotate(body, Common.random(0, 360));

      Composite.add(world, body);
    },
  };
}
