<template>
  <div class="gallery">
    <Poster v-for="poster in posters" :key="poster.name" v-bind="poster" />
  </div>
</template>

<script>
import { mapMutations } from "vuex";

import Poster from "./Poster";

export default {
  components: {
    Poster,
  },
  data() {
    return {
      posters: [
        {
          name: "Palooza5",
          // src: require("@/assets/img/posters/1/poster.jpg"),
          sequence: [
            require("@/assets/img/posters/3/2.jpg"),
            require("@/assets/img/posters/3/1.jpg"),
            require("@/assets/img/posters/3/3.jpg"),
            require("@/assets/img/posters/3/4.jpg"),
            require("@/assets/img/posters/3/5.jpg"),
            require("@/assets/img/posters/3/6.jpg"),
            require("@/assets/img/posters/3/7.jpg"),
            require("@/assets/img/posters/3/8.jpg"),
            require("@/assets/img/posters/3/9.jpg"),
            require("@/assets/img/posters/3/10.jpg"),
            require("@/assets/img/posters/3/11.jpg"),
            require("@/assets/img/posters/3/12.jpg"),
          ],
          hoverSrc: require("@/assets/img/posters/3/hover.jpg"),
          bg: "#ddd",
        },
        {
          name: "Palooza4",
          // src: require("@/assets/img/posters/1/poster.jpg"),
          sequence: [
            require("@/assets/img/posters/1/1.jpg"),
            require("@/assets/img/posters/1/2.jpg"),
            require("@/assets/img/posters/1/3.jpg"),
            require("@/assets/img/posters/1/4.jpg"),
          ],
          hoverSrc: require("@/assets/img/posters/1/hover.jpg"),
          bg: "lime",
        },
        {
          name: "HolidaySpecial1",
          sequence: [
            require("@/assets/img/posters/2/1.jpg"),
            require("@/assets/img/posters/2/2.jpg"),
            require("@/assets/img/posters/2/3.jpg"),
            require("@/assets/img/posters/2/4.jpg"),
            require("@/assets/img/posters/2/5.jpg"),
            require("@/assets/img/posters/2/6.jpg"),
            require("@/assets/img/posters/2/7.jpg"),
            require("@/assets/img/posters/2/8.jpg"),
            require("@/assets/img/posters/2/9.jpg"),
            require("@/assets/img/posters/2/10.jpg"),
            require("@/assets/img/posters/2/11.jpg"),
          ],
          hoverSrc: require("@/assets/img/posters/2/hover.jpg"),
          bg: "#F3F1EB",
        },
      ],
    };
  },
  methods: {
    ...mapMutations(["setGlobalBackgroundColor"]),
  },
  created() {
    this.setGlobalBackgroundColor({ color: "black" });
  },
};
</script>

<style lang="scss" scoped>
.gallery {
  cursor: default;
  --gap: 4vw;
  display: grid;
  grid-template-columns: repeat(3, 50vmin);
  align-items: center;
  height: 100svh;
  padding: 0 var(--gap);
  scroll-padding-left: calc(var(--gap) * 2);
  padding-left: 50vmin;
  padding-right: 50vmin;
  column-gap: var(--gap);
  row-gap: calc(var(--gap) * 2);
  overflow: auto;
  scroll-snap-type: x mandatory;

  @media (orientation: portrait) {
    grid-template-columns: repeat(3, 80vmin);
  }

  &::-webkit-scrollbar {
    display: none;
  }

  > * {
    scroll-snap-align: start;
  }

  /* @media (orientation: portrait) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    padding: var(--gap);
    --gap: 10vh;
    row-gap: calc(var(--gap) * 1);
  } */
}
</style>
