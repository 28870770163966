<template>
  <div class="timeline">
    <div class="container">
      <Hola />
      <img :src="require('./svg/1.svg')" v-svg-inline />
      <img :src="require('./svg/2.svg')" v-svg-inline />
      <img :src="require('./svg/3.svg')" v-svg-inline />
      <img :src="require('./svg/4.svg')" v-svg-inline />
      <img :src="require('./svg/5.svg')" v-svg-inline />
      <img :src="require('./svg/6.svg')" v-svg-inline />
      <Hola
        text="Pay • Get paid • Pay • Get paid • Pay • Get paid • Pay • Get paid • Pay • Get paid • "
      />
      <img :src="require('./svg/7.svg')" v-svg-inline />
      <img :src="require('./svg/8.svg')" v-svg-inline />
      <img :src="require('./svg/9.svg')" v-svg-inline />
      <img :src="require('./svg/10.svg')" v-svg-inline />
      <img :src="require('./svg/11.svg')" v-svg-inline />
      <img :src="require('./svg/12.svg')" v-svg-inline />
      <img :src="require('./svg/12.svg')" v-svg-inline />
    </div>
  </div>
</template>

<script>
import { Pane } from "tweakpane";
import gsap from "gsap";

import Hola from "./Hola";

export default {
  components: {
    Hola,
  },
  data() {
    return {
      items: [],
      index: -1,
      params: {
        label: "$700",
        interval: 300,
      },
    };
  },
  methods: {
    init() {
      this.items = [...this.$el.querySelectorAll(":scope > .container > *")];
      this.interval = setInterval(this.loop, this.params.interval);

      // init pane

      this.pane = new Pane({
        title: "Parameters",
        expanded: true,
      });

      this.pane
        .addInput(this.params, "interval", {
          min: 100,
          max: 1000,
          step: 10,
        })
        .on("change", (e) => {
          console.log("interval", this.params.interval);
          clearInterval(this.interval);
          this.interval = setInterval(this.loop, this.params.interval);
        });
    },
    loop() {
      this.index++;

      if (this.index > this.items.length - 1) {
        this.index = 0;
        gsap.set(this.items, { opacity: 0 });
      }

      gsap.set(this.items[this.index], { opacity: 1 });
    },
  },
  mounted() {
    this.$nextTick(this.init);
  },
  beforeUnmount() {
    if (this.interval) clearInterval(this.interval);
  },
};
</script>

<style>
@font-face {
  font-family: ABC;
  font-weight: normal;
  src: url("../../assets/fonts/ABCMonumentGrotesk-Medium.otf")
    format("opentype");
}
.tp-dfwv {
  text-transform: none;
}
</style>

<style lang="scss" scoped>
.timeline {
  background: #e5e5e5;
  background: black;
  display: grid;
  place-items: center;
  width: 100vw;
  height: 100vh;
}
.container {
  width: 60vmin;
  height: 60vmin;
  width: 100vmin;
  height: 100vmin;
  background: #e5e5e5;
  position: relative;

  > * {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #e5e5e5;
    opacity: 0;
  }
}
</style>
