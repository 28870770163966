import { N8AOPostPass } from "n8ao";
import {
  EffectComposer,
  EffectPass,
  RenderPass,
  BlendFunction,
  ChromaticAberrationEffect,
  TiltShiftEffect,
  NoiseEffect,
  ToneMappingEffect,
} from "postprocessing";

// ...
function postprocessing({ scene, camera, renderer, alpha, width, height } = { alpha: false }) {
  const effects = [];
  const props = { multisampling: 8 };
  const composer = new EffectComposer(renderer, props);

  function addAOPass() {
    /* Only difference is that N8AOPostPass requires a RenderPass before it, whereas N8AOPass replaces the render pass. Everything else is identical. */
    composer.addPass(new RenderPass(scene, camera));

    const n8aopass = new N8AOPostPass(scene, camera, width, height);
    // n8aopass.configuration.aoSamples = 16;
    // n8aopass.configuration.denoiseSamples = 4;
    // n8aopass.configuration.denoiseRadius = 12;

    n8aopass.configuration.aoRadius = 20.0;
    n8aopass.configuration.distanceFalloff = 4.0;
    n8aopass.configuration.intensity = 9.0;
    n8aopass.setQualityMode("Performance");

    composer.addPass(n8aopass);

    //n8aopass.setDisplayMode("Split AO");
  }

  function addSMAAPass() {
    const smaa = new SMAAEffect({
      preset: SMAAPreset.ULTRA,
    });
    effects.push(smaa);
  }

  function addToneMappingPass() {
    const toneMap = new ToneMappingEffect({
      minLuminance: 0.1,
      middleGrey: 0.95,
    });
    effects.push(toneMap);
  }

  function addTiltShiftPass() {
    const tiltShift = new TiltShiftEffect({
      resolutionScale: 1,
      offset: 0.0,
      rotation: -1.5,
      focusArea: 0.5,
      feather: 0.9,
      kernelSize: 3,
      blendFunction: BlendFunction.MULTIPLY,
    });
    effects.push(tiltShift);
  }

  function addNoisePass() {
    const noiseEffect = new NoiseEffect({
      blendFunction: BlendFunction.HARD_LIGHT,
      premultiply: false,
    });
    noiseEffect.blendMode.opacity.value = 0.05;
    effects.push(noiseEffect);
  }

  function addEffectPasses() {
    const effectPass = new EffectPass(camera, ...effects);
    effectPass.renderToScreen = true;

    // Create a pass for each effect.
    const passes = effects.map((effect) => new EffectPass(camera, effect));

    passes[passes.length - 1].renderToScreen = true;

    // Add all passes to the composer.
    for (const pass of passes) {
      pass.setEnabled(false);
      composer.addPass(pass);
    }

    composer.addPass(effectPass);
  }

  addAOPass();

  addTiltShiftPass();
  addToneMappingPass();
  addNoisePass();
  //addSMAAPass();

  // batch add
  addEffectPasses();

  return {
    composer,
    tiltShift: effects[0],
  };
}

export default postprocessing;
