<template>
  <div class="tp-5">
    <ratio v-if="loaded">
      <poster
        @ready="showLittleMermaid = true"
        @hover="isHovering = $event"
        @name="selectedName = $event"
      />
      <emojis :name="selectedName" />
    </ratio>
    <little-mermaid :show="showLittleMermaid && !isHovering" @ready="loaded = true" />
  </div>
</template>

<script>
import Ratio from "./Ratio";
import Poster from "./Poster";
import LittleMermaid from "./LittleMermaid/LittleMermaid";
import Emojis from "./Emojis/Emojis";

import { mapMutations } from "vuex";

export default {
  components: {
    Ratio,
    Poster,
    LittleMermaid,
    Emojis,
  },
  data() {
    return {
      loaded: false,
      selectedName: null,
      showLittleMermaid: false,
      isHovering: false,
    };
  },
  methods: {
    ...mapMutations(["setGlobalBackgroundColor"]),
  },
  mounted() {
    this.setGlobalBackgroundColor({ color: "#000" });
  },
};
</script>

<style scoped>
.tp-5 {
  width: 100svw;
  height: 100svh;
  background: black;
}
</style>
