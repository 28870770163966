<template>
  <div class="poster" :class="{ active: active }">
    <div class="pane group line row">
      <div class="text">Tolapalooza</div>
      <div class="line col-3">
        <div class="text center">Five</div>
        <div class="text center">June 5 - 9</div>
        <div class="text center">2023</div>
      </div>
    </div>
    <div class="pane line col-2 theme-win95-dark">
      <div class="text">Alex Johansson</div>
      <div class="text">Alen Cvisic</div>
    </div>
    <div class="pane line theme-win95-light">
      <div class="text center">Paulius Volikas</div>
    </div>
    <div class="pane line theme-notbrex-light">
      <div class="line col-2">
        <div class="text left">Evan Dreyer</div>
        <div class="text left">Nick Pozoulakis +1 +1</div>
      </div>
      <div class="line">
        <div class="text center">Samuel Kantala</div>
      </div>
    </div>

    <div class="pane line col-2 theme-blue-dark">
      <div class="text">Jakob Henner</div>
      <div class="text">Bianca Bossé</div>
    </div>
    <div class="pane line theme-lavender-light">
      <div class="text right">Marc-Antoine Ferland</div>
    </div>
    <div class="pane line col-2 theme-lavender-dark">
      <div class="text center end">Tom Bates</div>
      <div class="text">Guillaume Simard</div>
    </div>
    <div class="pane line theme-blue-light">
      <div class="text">Trevor Orsztynowicz</div>
    </div>
    <div class="pane group void" />
    <div class="pane group line col-2">
      <div class="text">Zealand</div>
      <div class="text right">Edition</div>
    </div>
    <slot />
  </div>
</template>

<script>
import gsap from "gsap";
import hammer from "hammerjs";

export default {
  data() {
    return {
      active: false,
      hoverMode: false,
      paloozaMode: false,
    };
  },
  methods: {
    createGestures() {
      this.manager = new hammer.Manager(this.$el);

      this.tap = new hammer.Tap();

      this.manager.add([this.tap]);
      this.manager.on("tap", this.onTap);
    },
    createMouseEvents() {
      this.panes.forEach((pane) => {
        pane.addEventListener("mouseover", this.onHover);
        pane.addEventListener("mouseleave", this.onHoverEnd);
      });
    },
    createTimeline() {
      this.timeline = gsap.timeline({
        onComplete: () => {
          this.$el
            .querySelectorAll(".pane, .text")
            .forEach((el) => el.classList.add("active"));

          this.$emit("ready");

          if (this.isTouchDevice) {
            this.createGestures();
          } else {
            this.createMouseEvents();
          }
        },
      });

      this.names.forEach((name, n) => {
        this.timeline.to(name, {
          duration: 0.5,
          onStart: () => {
            this.addNameClass(name);
          },
          onComplete: () => {
            this.removeNameClass(name);
          },
        });
      });

      this.timeline.to(this.groups, {
        duration: 0.5,
        onStart: () => {
          this.groups.forEach((el) => el.classList.add("active"));
          this.active = true;
        },
      });
    },
    createPaloozaTimeline() {
      this.$emit("ready");
      this.timeline = gsap.timeline({ repeat: -1 });

      this.names.forEach((name, n) => {
        this.timeline.to(name, {
          duration: 10,
          onStart: () => {
            this.onHover({
              target: name,
            });
          },
        });
      });

      this.timeline.to(
        {},
        {
          duration: 15,
          onStart: () => {
            this.onHoverEnd();
          },
        }
      );
    },

    onTap(e) {
      if (this.hoverMode) {
        this.onHoverEnd();
      } else {
        this.onHover(e);
      }
    },
    onHover(e) {
      const classList = e.target.classList;
      this.hoverMode = true;
      this.$emit("hover", true);

      this.names
        .filter((name) => name !== e.target)
        .forEach((name) => this.removeNameClass(name));

      if (classList.contains("text")) {
        this.$emit("name", e.target.textContent);
      }

      if (classList.contains("text") || classList.contains("pane:not(.group)"))
        this.addNameClass(e.target);
    },
    onHoverEnd() {
      this.hoverMode = false;
      this.$emit("hover", false);
      this.$emit("name", null);
      this.names.forEach((name) => this.addNameClass(name));
    },

    addNameClass(name) {
      const parent = name.closest(".pane");
      name.classList.add("active");
      parent.classList.add("active");
    },
    removeNameClass(name) {
      const parent = name.closest(".pane");
      name.classList.remove("active");
      parent.classList.remove("active");
    },
    reset() {
      // reset
      this.names.forEach((name, n) => {
        this.removeNameClass(name);
      });

      this.panes.forEach((name, n) => {
        this.removeNameClass(name);
      });
    },

    removeEvents() {
      if (this.isTouchDevice) {
        if (this.manager) this.manager.destroy();
      } else {
        this.panes.forEach((pane) => {
          pane.removeEventListener("mouseover", this.onHover);
          pane.removeEventListener("mouseleave", this.onHoverEnd);
        });
      }
    },
  },
  computed: {
    isTouchDevice() {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    },
    names() {
      return [...this.$el.querySelectorAll(".pane:not(.group) .text")];
    },
    panes() {
      return [...this.$el.querySelectorAll(".pane:not(.group)")];
    },
    groups() {
      return [...this.$el.querySelectorAll(".group")];
    },
  },
  mounted() {
    if (this.paloozaMode) {
      this.groups.forEach((el) => el.classList.add("active"));
      this.active = true;
      this.createPaloozaTimeline();
    } else {
      this.reset();
      this.createTimeline();
    }
  },
  beforeUnmount() {
    if (this.timeline) this.timeline.kill();
    this.removeEvents();
  },
};
</script>

<style lang="scss" scoped>
.poster {
  --base-unit: max(0.6px, 1 / 990 * 100vmin);
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;

  &.active {
    background: #eee;
  }
}
.pane {
  opacity: 0;

  &.active {
    opacity: 1;
  }
}
.void {
  flex: 1;
  background: #eee;
  opacity: 0;

  &.active {
    opacity: 1;
  }
}
.group {
  .text {
    opacity: 1;
  }
}
.line {
  background: #eee;
  padding: calc(3 * var(--base-unit)) calc(12 * var(--base-unit));
  padding-bottom: calc(1 * var(--base-unit));
  display: grid;
  //gap: calc(12 * var(--base-unit));
  transform: translateZ(0px);

  .line {
    padding: 0;
    background: transparent;
  }

  &.col-2 {
    grid-template-columns: 1fr 1fr;
    gap: calc(12 * var(--base-unit));
  }
  &.col-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  &.row {
    grid-template-rows: 1fr 1fr;
  }
}

.theme {
  &-win95 {
    &-dark {
      background: #04493a;
      color: #b3e5da;
    }
    &-light {
      background: #b3e5da;
      color: #04493a;
    }
  }
  &-notbrex {
    &-dark {
    }
    &-light {
      background: #f9cdc6;
      color: #8a200f;
    }
  }
  &-lavender {
    &-dark {
      background: #1c0891;
      color: #beb3fd;
    }
    &-light {
      background: #beb3fd;
      color: #1c0891;
    }
  }
  &-blue {
    &-dark {
      background: #458cff;
      color: #021e4b;
    }
    &-light {
      background: #b4d0fd;
      color: #021e4b;
    }
  }
}

.end {
  align-self: end;
}

.text {
  font-size: calc(35.7143 * var(--base-unit));
  line-height: calc(33 * var(--base-unit));
  position: relative;
  opacity: 0;
  cursor: pointer;
  height: 100%;

  &.active {
    opacity: 1;
  }

  &::after {
    content: "";
    position: absolute;
    background: red;
    width: 100%;
    height: 100%;
    z-index: 100000000;
    top: 0;
    left: 0;
    mix-blend-mode: difference;
    opacity: 0;
  }

  &.right {
    text-align: right;
  }
  &.center {
    text-align: center;
  }
}

/* .text::before {
  content: "";
  margin-bottom: -0.079em;
  display: table;
}

.text::after {
  content: "";
  margin-top: -0.145em;
  display: table;
} */
</style>
