<template>
  <div class="marbles" :class="{ show: name }"></div>
</template>

<script>
import marbles from "./marbles";

import { Common } from "matter-js";
import { interpolateNumber } from "d3-interpolate";
import { debounce } from "lodash";

export default {
  props: {
    name: String,
  },
  data() {
    return {
      gravityX: 0,
      gravityY: 0,
      bounds: null,
      pos: {
        x: 0,
        y: 0,
      },
    };
  },
  methods: {
    onResize: debounce(function () {
      this.bounds = this.$el.getBoundingClientRect();
      // this.stop();
      // this.$nextTick(this.init);
      this.marbles.resize(this.bounds.width, this.bounds.height);
    }, 0),
    //     onMousemove(e) {
    //       this.bounds = this.$el.getBoundingClientRect();
    //
    //       const x = (e.clientX - this.bounds.left) / this.bounds.width;
    //       const y = (e.clientY - this.bounds.top) / this.bounds.height;
    //
    //       // convert range from [0, 1] to [-1, 1]
    //       this.gravityX = x * 2 - 1;
    //       this.gravityY = y * 2 - 1;
    //     },
    //     onMouseleave(e) {
    //       this.gravityX = 1;
    //       this.gravityY = 1.5;
    //     },
    init() {
      this.bounds = this.$el.getBoundingClientRect();

      this.marbles = marbles({
        element: this.$el,
        width: this.bounds.width,
        height: this.bounds.height,
      });

      this.gravityX = 1;
      this.gravityY = 1;

      //this.draw();
    },
    stop() {
      if (!this.marbles) return;
      cancelAnimationFrame(this.raf);
      this.marbles.stop();
    },
    pause() {
      cancelAnimationFrame(this.raf);
      this.raf = null;
    },
    draw() {
      this.marbles.engine.world.gravity.x = this.gravityX;
      this.marbles.engine.world.gravity.y = this.gravityY;
      this.raf = requestAnimationFrame(this.draw);
    },
  },
  watch: {
    name() {
      if (this.name) {
        const map = {
          "Alen Cvisic": "party-alen.png",
          "Alex Johansson": "alex-quiz-master.png",
          "Marc-Antoine Ferland": "marco-2.png", //["marco-1.png", "marco-2.png", "marco-3.png", "marco-4.png"],
          "Samuel Kantala": "sammy.png", //["sammy.png", "win95.png"],
          "Paulius Volikas": "pauly.png",
          "Tom Bates": "redbull.png",
          "Jakob Henner": "jakob.png",
          "Guillaume Simard": "gui.png",
          "Bianca Bossé": "bianca.png",
          "Nick Pozoulakis +1 +1": ["coda-1.png", "coda-2.png"],
          "Trevor Orsztynowicz": "trevor.png",
          "Evan Dreyer": [
            "evan-6.png",
            "evan-7.png",
            "evan-8.png",
            "evan-9.png",
            "evan-10.png",
            "evan-11.png",
            "evan-12.png",
            "evan-13.png",
            "evan-14.png",
            "evan-15.png",
          ],
        };

        const emoji = map[this.name];

        this.gravityX = Common.random(-0.25, 0.25);
        this.gravityY = Common.random(-0.25, 0.25);
        this.marbles.addFaces(emoji);
        if (!this.raf) this.draw();
      } else {
        this.pause();
        this.marbles.removeFaces();
      }
    },
  },
  mounted() {
    this.observer = new ResizeObserver(this.onResize);
    this.observer.observe(this.$el);

    this.init();
  },
  beforeUnmount() {
    if (this.marbles) {
      this.stop();
    }
    this.observer.unobserve(this.$el);
  },
};
</script>

<style lang="scss" scoped>
.marbles {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  pointer-events: none;
  opacity: 0;

  &.show {
    opacity: 1;
  }
}
</style>
