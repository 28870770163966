<template>
  <div
    class="poster"
    :class="{ hover: hover }"
    :style="style"
    @click="$router.push({ name: name })"
    @mouseover="start"
    @mouseleave="stop"
    @touchstart="start"
    @touchend="stop"
  >
    <img :src="computedSrc" />
    <img class="carousel" :src="hoverSrc" v-if="hoverSrc" />
  </div>
</template>

<script>
export default {
  props: {
    src: String,
    hoverSrc: String,
    sequence: Array,
    name: String,
    bg: {
      type: String,
      default: "grey",
    },
  },
  data() {
    return {
      sequenceIndex: 0,
      hover: false,
    };
  },
  methods: {
    nextImage() {
      if (this.sequenceIndex === this.sequence.length - 1) {
        this.sequenceIndex = 0;
      } else {
        this.sequenceIndex++;
      }
    },
    start() {
      if (this.sequence) {
        this.interval = setInterval(this.nextImage, 500);
        this.hover = true;
      }
    },
    stop() {
      if (this.interval) {
        clearInterval(this.interval);
        this.hover = false;
      }
    },
  },
  computed: {
    style() {
      return {
        backgroundColor: this.bg,
      };
    },
    computedSrc() {
      if (!this.sequence) return this.src;

      return this.sequence[this.sequenceIndex];
    },
  },
  beforeUnmount() {
    this.stop();
  },
};
</script>

<style lang="scss" scoped>
.poster {
  aspect-ratio: 89/126;
  cursor: pointer;
  position: relative;
  width: 100%;
  user-select: none;

  &.hover {
    img.carousel {
      opacity: 0;
    }
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    opacity: 1;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;

    &.carousel {
      opacity: 1;
    }
  }
}
</style>
