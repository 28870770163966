<template>
  <div class="palooza-4-faces bg" />
</template>

<script>
import sketch from "./sketch";
import { interpolateNumber } from "d3-interpolate";
const gravity = interpolateNumber(-5, 5);

import { debounce } from "lodash";

export default {
  props: ["path"],
  data() {
    return {
      gravityX: 0.5,
      gravityY: 0.5,
      mouseX: 0,
      mouseY: 0,
      controlGravity: false,
    };
  },
  methods: {
    start() {
      const bounds = this.$el.getBoundingClientRect();
      this.sketch = sketch({
        element: this.$el,
        width: bounds.width,
        height: bounds.height,
      });
      window.addEventListener("resize", this.onResize);
      document.addEventListener("mousemove", this.onMousemove);
      document.addEventListener("keydown", this.onKeydown);
    },
    stop() {
      if (this.raf) cancelAnimationFrame(this.raf);
      this.sketch.stop();
      window.removeEventListener("resize", this.onResize);
      document.removeEventListener("mousemove", this.onMousemove);
      document.removeEventListener("keydown", this.onKeydown);
    },
    draw() {
      this.sketch.move(this.gravityX, this.gravityY);
      this.raf = requestAnimationFrame(this.draw);
    },
    onKeydown(e) {
      if (e.code === "Space") {
        this.controlGravity = !this.controlGravity;

        if (this.controlGravity) {
          this.draw();
        } else {
          cancelAnimationFrame(this.raf);

          this.sketch.move(0, 1);
        }
      }

      if (e.code === "Enter") {
        this.sketch.add(this.mouseX, this.mouseY);
      }
      if (e.key === "m") {
        for (let i = 0; i < 10; i++) {
          this.sketch.add(this.mouseX, this.mouseY);
        }
      }
      if (e.key === "b") {
        this.sketch.addButt(this.mouseX, this.mouseY);
      }
    },
    onClick(e) {
      this.sketch.add(e.clientX, e.clientY);
    },
    onMousemove(e) {
      this.mouseX = e.clientX;
      this.mouseY = e.clientY;
      const x = e.clientX / window.innerWidth;
      const y = e.clientY / window.innerHeight;

      this.gravityX = gravity(x);
      this.gravityY = gravity(y);
    },
    onResize: debounce(function () {
      this.stop();
      this.$nextTick(this.start);
    }, 0),
  },
  mounted() {
    this.start();
  },
  unmounted() {
    this.stop();
  },
};
</script>

<style lang="scss">
.palooza-4-faces {
  position: absolute;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  //border-radius: 18vw;
  overflow: hidden;
  clip-path: url(#squircleClip);

  > canvas {
    filter: grayscale(1) brightness(1.15);
    mix-blend-mode: multiply;

    @at-root .inverse & {
      mix-blend-mode: hard-light;
    }
  }
}
</style>
