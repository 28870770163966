<template>
  <div class="holiday-1 bg" />
</template>

<script>
import sketch from "./sketch";
import { interpolateNumber } from "d3-interpolate";
import { debounce } from "lodash";
const gravity = interpolateNumber(-5, 5);

export default {
  props: ["path"],
  data() {
    return {
      gravityX: 0.5,
      gravityY: 0.5,
      mouseX: 0,
      mouseY: 0,
      isSlowMo: false,
      isHighFi: false,
      controlGravity: false,
    };
  },
  methods: {
    start() {
      const bounds = this.$el.getBoundingClientRect();
      this.sketch = sketch({
        element: this.$el,
        width: bounds.width,
        height: bounds.height,
      });
      window.addEventListener("resize", this.onResize);
      document.addEventListener("mousemove", this.onMousemove);
      document.addEventListener("keydown", this.onKeydown);
    },
    stop() {
      if (this.raf) cancelAnimationFrame(this.raf);
      this.sketch.stop();
      window.removeEventListener("resize", this.onResize);
      document.removeEventListener("mousemove", this.onMousemove);
      document.removeEventListener("keydown", this.onKeydown);
    },
    draw() {
      this.sketch.move(this.gravityX, this.gravityY);
      this.raf = requestAnimationFrame(this.draw);
    },
    onKeydown(e) {
      if (e.code === "Space") {
        this.sketch.newSmallDoll();
      }
      if (e.code === "KeyN") {
        this.sketch.newDoll();
      }
      if (e.code === "KeyS") {
        this.isHighFi = false;
        this.isSlowMo = this.sketch.slowMo();
      }
      // if (e.code === "KeyF") {
      //   this.isSlowMo = false;
      //   this.isHighFi = this.sketch.highFi();
      // }
    },
    onClick(e) {
      this.sketch.add(e.clientX, e.clientY);
    },
    onMousemove(e) {
      this.mouseX = e.clientX;
      this.mouseY = e.clientY;
      const x = e.clientX / window.innerWidth;
      const y = e.clientY / window.innerHeight;

      this.gravityX = gravity(x);
      this.gravityY = gravity(y);
    },
    onResize: debounce(function () {
      this.stop();
      this.$nextTick(this.start);
    }, 0),
  },
  watch: {
    isSlowMo() {
      this.$emit("slowmo", this.isSlowMo);
    },
    isHighFi() {
      this.$emit("highfi", this.isHighFi);
    },
  },
  mounted() {
    this.start();
  },
  unmounted() {
    this.stop();
  },
};
</script>

<style lang="scss">
.holiday-1 {
  position: absolute;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  //border-radius: 18vw;
  overflow: hidden;
  clip-path: url(#squircleClip);

  > canvas {
    // filter: grayscale(1) brightness(1.15);
    // mix-blend-mode: multiply;

    @at-root .inverse & {
      //filter: grayscale(1) brightness(1.25);
    }
  }
}
</style>
