const hdrMaps = [
  "sunflowers_puresky_1k.hdr",
  "blue_photo_studio_1k.hdr",
  "industrial_sunset_puresky_1k.hdr",
  "mud_road_puresky_1k.hdr",
  "sunflowers_puresky_1k.hdr",
  "table_mountain_2_puresky_1k.hdr", // 5 <--- nice!
  "industrial_sunset_02_puresky_1k.hdr",
  "aristea_wreck_puresky_1k.hdr",
  "wasteland_clouds_puresky_1k.hdr",
  "rocky_ridge_puresky_1k.hdr", // 9 -- nice, more muted, but too white
  "marry_hall_1k.hdr",
  "studio_small_05_1k.hdr", // 11 - nice shadows when you tilt
  "blocky_photo_studio_1k.hdr", // 12 cute
  "neon_photostudio_1k.hdr", // 13 - super nice shadows - neon light a bit too much
];

const createHdrParams = () => {
  const obj = {};
  hdrMaps.map((map, n) => {
    obj[map] = n;
  });
  return obj;
};

export { hdrMaps, createHdrParams };
