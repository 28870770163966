import { createStore } from "vuex";

export default createStore({
  state: {},
  mutations: {
    setGlobalBackgroundColor(state, { color, p3 }) {
      let style = `background: ${color};`;
      if (p3) style += `background: ${p3}`;

      document.body.style = style;

      // OS chrome
      document
        .querySelector('meta[name="theme-color"]')
        .setAttribute("content", p3 || color);
    },
  },
  actions: {},
  modules: {},
  getters: {},
});
